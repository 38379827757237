// src/ItemGrid.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from './firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import './ItemGrid.css';


function Tees() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'tees'));
        const itemsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setItems(itemsData);
      } catch (error) {
        console.error("Error fetching items: ", error);
      }
    };

    fetchItems();
  }, []);

  return (
    <div className="item-grid">
      
      {items.map(item => (
        <Link key={item.id} to={`/item/${item.id}`} className="item-link">
          <div className="item">
            <img src={item.image+".png"} alt={item.name} className="item-image" />
            <div className="item-name">{item.name}</div>
            <div className="item-price">${item.price}</div>
          </div>
        </Link>
      ))}
    </div>
    
  );
}

export default Tees;
