import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './subnav.css';

/*
        <li className="navbar-item"><Link to="/">BOTTOMS</Link></li>
        <li className="navbar-item"><Link to="/">TOYS</Link></li>
        <li className="navbar-item"><Link to="/">OTHER</Link></li>
*/
function subnav() {
  return (
    <nav className="subnav">
      <h3>U.S Shipping Only | Free Shipping | Tax Included</h3>
    </nav>
  );
}

export default subnav;
