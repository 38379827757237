import React, { useEffect, useState } from 'react';
import { db } from './firebaseConfig';
import { collection, getDocs, query, where, startAt, endAt } from 'firebase/firestore';
import './ItemGrid.css';
import ItemNav from './itemnav'; // Import the ItemNav component
import { Link, useLocation } from 'react-router-dom';

function ItemGrid({ searchQuery }) {
  const [items, setItems] = useState([]);
  const [category, setCategory] = useState('tees'); // Default category
  const [sortCriteria, setSortCriteria] = useState(''); // Default sorting criteria
  const [setSearchQuery] = useState('');
  const location = useLocation();

  useEffect(() => {
    const fetchItems = async () => {
      try {
        let q;
  

        if (searchQuery) {
          // Fetch all items and filter in memory to find matches
          const allItemsQuerySnapshot = await getDocs(collection(db, 'items'));
          const allItemsData = allItemsQuerySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
    
          const filteredItems = allItemsData.filter(item =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
          );
    
          setItems(filteredItems);
        } else if (category) {
          q = query(collection(db, 'items'), where('type', '==', category));
        } else {
          q = collection(db, 'items');
        }
    
        if (!searchQuery) {
          const querySnapshot = await getDocs(q);
          const itemsData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setItems(itemsData);
        }
      } catch (error) {
        console.error('Error fetching items: ', error);
      }
    };
    
    

    fetchItems();
  }, [category, searchQuery, location.pathname]); // Include searchQuery in dependency array

  const handleCategoryChange = (newCategory) => {
    setCategory(newCategory);
  };

  const handleSortChange = (e) => {
    setSortCriteria(e.target.value);
  };

  const getSortedItems = (items) => {
    let sortedItems = [...items];
    switch (sortCriteria) {
      case 'price-asc':
        sortedItems.sort((a, b) => a.price - b.price);
        break;
      case 'price-desc':
        sortedItems.sort((a, b) => b.price - a.price);
        break;
      case 'name-asc':
        sortedItems.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'name-desc':
        sortedItems.sort((a, b) => b.name.localeCompare(a.name));
        break;
      default:
        break;
    }
    return sortedItems;
  };

  const sortedItems = getSortedItems(items);

  return (
    <div>
      <ItemNav onCategoryChange={handleCategoryChange} />
      <div className="sort-filter">
        <label htmlFor="sort">Sort by:</label>
        <select id="sort" value={sortCriteria} onChange={handleSortChange}>
          <option value="">None</option>
          <option value="price-asc">Price: Low to High</option>
          <option value="price-desc">Price: High to Low</option>
          <option value="name-asc">Name: A to Z</option>
          <option value="name-desc">Name: Z to A</option>
        </select>
      </div>
      <div className="item-grid">
        {sortedItems.map((item) => (
          <div key={item.id} className="item-link">
            <Link key={item.id} to={`/item/${item.id}`} className="item-link">
              <div className="item">
                <img src={item.url} alt={item.name} className="item-image" />
                <div className="item-name">{item.name}</div>
                <div className="item-price">${item.price}</div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ItemGrid;
