// src/Navbar.js
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom'; 
import './Navbar.css';

function Navbar({ onSearch }) {
  const navigate = useNavigate();
  const [showSearch, setShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const searchFormRef = useRef(null);

  const handleHomeClick = () => {
    navigate('/'); // Navigate to home route
    window.location.reload(); // Reload the page
  };

  const handleSearchClick = () => {
    setShowSearch(!showSearch);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    onSearch(searchQuery);
    setShowSearch(!showSearch);
  };

  const handleClickOutside = (event) => {
    if (searchFormRef.current && !searchFormRef.current.contains(event.target)) {
      setShowSearch(false);
    }
  };

  useEffect(() => {
    if (showSearch) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSearch]);

  return (
    <nav className="navbar">
      <ul className="navbar-list">
      <li className="logo-item"> 
          <img src="./timelogo.png" alt="Time Logo" className="timelogo" />
        </li>        <li className="navbar-item"><Link to="/" onClick={handleHomeClick}>HOME</Link></li>
        <li className="navbar-item"><Link to="/contact">CONTACT</Link></li>
        <li className="navbar-item"><Link to="/archive">ARCHIVE</Link></li>
        
        {/* 
        <li className="navbar-item">
          <div className="search">
            <button onClick={handleSearchClick} className="search-button">
              <img src="./search.png" alt="Search" className="search-icon" />
            </button>
          </div>
        </li>
        */}

      </ul>

      {showSearch && (
        <form ref={searchFormRef} onSubmit={handleSearchSubmit} className="search-form">
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search..."
            className="search-input"
          />
          <button type="submit" className="search-submit-button">Go</button>
        </form>
      )}
    </nav>
  );
}

export default Navbar;
