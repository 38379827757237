// Cart.js
import React from 'react';
import './cart.css'; // Import your CSS file for styling

function Cart() {
  return (
    <div className="">
      <h2>Cart</h2>
      <p>Cart is empty</p>
    </div>
  );
}

export default Cart;
