import React from 'react';
import './itemnav.css';

function ItemNav({ onCategoryChange }) {
  return (
    <nav className="itemnav">
      <ul>
        <li><button onClick={() => onCategoryChange('tees')}>Tees</button></li>
        <li><button onClick={() => onCategoryChange('tops')}>Tops</button></li>
        <li><button onClick={() => onCategoryChange('bottoms')}>Bottoms</button></li>
        <li><button onClick={() => onCategoryChange('hats')}>Hats</button></li>
        <li><button onClick={() => onCategoryChange('accessories')}>Accessories</button></li>
        <li><button onClick={() => onCategoryChange('media')}>Media</button></li>
        <li><button onClick={() => onCategoryChange('collectibles')}>Collectibles</button></li>
      </ul>
    </nav>
  );
}

export default ItemNav;
