// RefundPolicy.js
import React from 'react';
import './refund-policy.css'; // Import your CSS file for styling (create this file)

function RefundPolicy() {
  return (
    <div className="container">
      <h1>Refund Policy</h1>
      <ul>
        <li>All sales are final and sold in an as-is condition.</li>
        <li>Stains, holes, and other wear-n-tear are excepted, so make sure to throughly examine each product photo.</li>
        <li>Condition is showed in each product photo to our best ability.</li>
        <li>If an issue persists with an item, please contact us and we will do our best to fix it.</li>
        <li>If an item is returned to us due to an inaccurate address, please contact us with the correct address.</li>

      </ul>
    </div>
  );
}

export default RefundPolicy;
