// src/Checkout.js
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Checkout.css';

function Checkout() {
  const location = useLocation();
  const { item } = location.state;
  const [quantity, setQuantity] = useState(1);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  
  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleCheckout = () => {
    // Navigate to the payment page with item, quantity, and email data
    navigate('/payment', { state: { item, quantity, email } });
  };

  return (
    <div className="checkout">
      <h1>Checkout</h1>
      <div className="checkout-item">
        <img src={item.image} alt={item.name} className="checkout-item-image" />
        <div className="checkout-item-details">
          <div className="checkout-item-name">{item.name}</div>
          <div className="checkout-item-price">Price: ${item.price}</div>
          <div className="checkout-item-quantity">
 
          </div>
          <div className="checkout-item-email">

          </div>
        </div>
      </div>
      <button className="checkout-button" onClick={handleCheckout}>Proceed to Payment</button>
    </div>
  );
}

export default Checkout;
