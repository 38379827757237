import React, { useState, useEffect } from 'react';
import { PayPalScriptProvider, PayPalButtons, PayPalMarks } from '@paypal/react-paypal-js';
import { useLocation, useNavigate } from 'react-router-dom';
import { db } from './firebaseConfig';
import { doc, getDoc, deleteDoc } from 'firebase/firestore';
import './Checkout.css';
import './PayPalButton.css'; 
import Footer from './footer';

const PayPalButton = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [item, setItem] = useState(null);
  const [paypalError, setPaypalError] = useState(null);

  useEffect(() => {
    const fetchItem = async () => {
      try {
        if (location.state && location.state.item) {
          const itemRef = doc(db, 'items', location.state.item.name);
          const itemSnap = await getDoc(itemRef);
          if (itemSnap.exists()) {
            setItem(location.state.item);
          } else {
            setPaypalError('Item does not exist.');
          }
        } else {
          setPaypalError('Item details not found.');
        }
      } catch (error) {
        setPaypalError('Error fetching item: ' + error.message);
      }
    };
    fetchItem();
  }, [location.state]);

  const clientId = 'AWMlwS9oKraSiu5-4SJBSQELATOr7jkCEpdovQDDmkxWUrgvoloaRnpUkkwXgouCIgqwXngwC9qtFFVH'; 

  const createOrder = (data, actions) => {
    if (!item) {
      setPaypalError('Item is not defined.');
      return;
    }

    return actions.order.create({
      purchase_units: [{
        amount: {
          value: item.price
        },
        description: item.name,
        shipping: {
          address: {
            country_code: 'US',
            postal_code: '00000',  // Placeholder postal code
            admin_area_2: 'Sample City',  // Placeholder city
            admin_area_1: 'CA',  // Placeholder state
            address_line_1: '123 Sample St'  // Placeholder address line
          }
        }
      }]
    });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(async function(details) {
      console.log('Transaction completed by ' + details.payer.name.given_name);
      console.log('Payer email: ' + details.payer.email_address);
      console.log('Payer ID: ' + details.payer.payer_id);
      console.log('Shipping address: ', details.purchase_units[0].shipping);      await handleSuccessfulPayment();
    }).catch(function (error) {
      setPaypalError(error.message);
    });
  };

  const handleSuccessfulPayment = async () => {
    if (!item) {
      setPaypalError('Item is not defined.');
      return;
    }

    const itemRef = doc(db, 'items', item.name);

    try {
      console.log('Payment successful!');
      await deleteDoc(itemRef);
      console.log('Document successfully deleted!');
      navigate('/');
    } catch (error) {
      console.error('Error removing document: ', error);
      setPaypalError('Error completing payment. Please try again.');
    }
  };

  if (!item) {
    return <div>Loading...</div>;
  }

  return (
    <div className="paypal-button-container">
      <h1>Payment</h1>
      <p>Item: {item.name}</p>
      <p>Shipping: Included</p>
      <p>Sales Tax: Included</p>
      <p><b>Total: ${item.price}</b></p>
      <img src={item.url} className="pay-img" alt={item.name} />

      {paypalError && <p className="error-message">Error: {paypalError}</p>}

      <PayPalScriptProvider options={{ 
      "client-id": clientId,
      components: 'buttons,marks'
    }}>
        <PayPalMarks fundingSource="applepay" />
        <PayPalButtons createOrder={createOrder} onApprove={onApprove} onError={(error) => setPaypalError(error.message)} />
      </PayPalScriptProvider>
    </div>
  );
};

export default PayPalButton;
