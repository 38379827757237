// RefundPolicy.js
import React from 'react';
import './refund-policy.css'; // Import your CSS file for styling (create this file)

function shipping() {
  return (
    <div className="container">
      <h1>Shipping Policy</h1>
      <ul>
        <li>All orders are shippied within 1-5 business days.</li>
      </ul>
    </div>
  );
}

export default shipping;
