// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCy0TPg2jh5fIrCQn5X3iBO7jk6nOKuTuU",
    authDomain: "timemachinethrift-13c28.firebaseapp.com",
    databaseURL: "https://timemachinethrift-13c28-default-rtdb.firebaseio.com",
    projectId: "timemachinethrift-13c28",
    storageBucket: "timemachinethrift-13c28.appspot.com",
    messagingSenderId: "6409819232",
    appId: "1:6409819232:web:ebf6b158a9bdd699ea1f05",
    measurementId: "G-YT82KV06C0"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
