import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Contact.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here (e.g., send data to server)

    // Clear form after submission (optional)
    setFormData({
      name: '',
      email: '',
      phone: '',
      message: ''
    });
  };

  const validateEmail = (email) => {
    // Basic email validation regex
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isFormValid = () => {
    return formData.email && validateEmail(formData.email);
    // Add more validation logic as needed (e.g., phone number format)
  };

  return (
    <div className="container">
      <h2>Contact Us</h2>
      <p>EMAIL: timemachinethrift@gmail.com</p>
      <p>Instagram: timemachinethriftstore</p>
      <p>Depop: timemachinethrift</p>

    </div>
  );
};

export default ContactForm;
