// RefundPolicy.js
import React from 'react';
import './refund-policy.css'; // Import your CSS file for styling (create this file)

function PrivacyPolicy() {
  return (
    <div className="container">
      <h1>Privacy Policy</h1>
      <p>This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from timemachinethrift.com (the “Site”).</p>
      
      <h2>The Personal information We Collect</h2>
      <p>The types of personal information we obtain about you depends on how you interact with our Site and use our Services. When we use the term "personal information", we are referring to information that identifies, relates to, describes or can be associated with you. The following sections describe the categories and specific types of personal information we collect. The following data is collected by PayPal.</p>
      
      <ul>
        <li>Basic contact details including your name, address, phone number, email.</li>
        <li>Order information including your name, billing address, shipping address, email address, phone number.</li>
      </ul>

      <h2>Data Security</h2>
      <p>We prioritize the security of your information. We use industry-standard measures to protect your data during transmission and storage. PayPal handles all payment-related information securely and does not share it with us except for order confirmation purposes.</p>

      <h2>Analytics</h2>
      <p>We use Google Analytics to gather data about how users interact with our website. This helps us improve our services and user experience. Google Analytics collects information such as your IP address, browser type, and pages visited on our site. This data is aggregated and anonymized.</p>

      <h2>Changes to This Policy</h2>
      <p>We may update this Privacy Policy periodically. We will notify you of significant changes by posting the updated policy on our website.</p>

      <h2>Contact Us</h2>
      <p>If you have any questions about this Privacy Policy or our data practices, please contact us at [Contact Information].</p>
    </div>
  );
}

export default PrivacyPolicy;
