// src/ItemDetail.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import './ItemDetail.css';

function ItemDetail() {
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchItem = async () => {
      const docRef = doc(db, 'items', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setItem(docSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchItem();
  }, [id]);

  const handleBuyNow = () => {
    // Navigate to checkout page with item data
    navigate('/payment', { state: { item } });
  };

  if (!item) return <div>Loading...</div>;

  return (
    <div >
      <h1>{item.name}</h1>
      
      <img src={item.url} alt={item.name} className="item-detail-image" />
      <img src={item.url2} alt={item.name} className="item-detail-image" />
      <img src={item.url3} alt={item.name} className="item-detail-image" />
      <img src={item.url4} alt={item.name} className="item-detail-image" />
      <p>Condition {item.condition} {item.desc} </p>
      <p>Width: {item.wid} in. Length: {item.len} in.</p>
      <p>Measurements are closely approximate.</p>
      <p>Price: ${item.price}</p>
      <button className="buy-now-button" onClick={handleBuyNow}>Buy Now</button>
    </div>
  );
}

export default ItemDetail;
