import React from 'react';
import './refund-policy.css'; // Import your CSS file for styling (create this file)

function Terms() {
  return (
    <div className="container">
      <h1>Terms of Service</h1>
      <p>Welcome to TimeMachineThrift! These Terms of Service govern your use of our website and services. By accessing or using our services, you agree to comply with these terms.</p>
      
      <h2>Services Provided</h2>
      <p>TimeMachineThrift offers an online platform for the sale of clothing, toys, collectibles, and accessories for men and women.</p>
      
      <h2>User Conduct</h2>
      <p>Users agree not to engage in unlawful or prohibited activities on our platform. This includes but is not limited to fraud, unauthorized access, and violating intellectual property rights.</p>
      
      <h2>Payment Terms</h2>
      <p>All sales are final. Payment terms, including pricing, taxes, and shipping fees, are outlined during the checkout process.</p>
      
      <h2>Privacy</h2>
      <p>Our Privacy Policy outlines how we collect, use, and protect your personal information. By using our services, you consent to our privacy practices.</p>
      
      <h2>Termination</h2>
      <p>TimeMachineThrift reserves the right to suspend or terminate your account for violations of these terms or for any reason at our discretion.</p>

      <h2>Contact Us</h2>
      <p>If you have any questions about these Terms of Service or our services, please contact us at [Contact Information].</p>
    </div>
  );
}

export default Terms;
