// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Navbar from './Navbar';
import Subnav from './subnav';
import ItemGrid from './ItemGrid';
import ItemDetail from './ItemDetail';
import Checkout from './Checkout';
import Payment from './Payment';
import Contact from './contact';
import Refund from './refund-policy';
import Privacy from './privacy_policy';
import Cart from './cart';
import Shipping from './shipping';
import Terms from './terms';
import Footer from './footer';
import Nav from './itemnav';
import Tees from './tees';

function App() {
  const location = useLocation();
  const showNavPaths = ['/', '/tees', '/bottoms', '/hats', '/accessories', '/media', '/collectibles'];

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  return (
    <div className="App">
      <Subnav />
      <Navbar onSearch={handleSearch} />
      <div className="content">
        <Routes>
          <Route path="/" element={<ItemGrid searchQuery={searchQuery} />} />
          <Route path="/item/:id" element={<ItemDetail />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/refund-policy" element={<Refund />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/shipping" element={<Shipping />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/tees" element={<Tees />} />
          <Route path="/bottoms" element={<ItemGrid category="bottoms" searchQuery={searchQuery} />} />
          <Route path="/hats" element={<ItemGrid category="hats" searchQuery={searchQuery} />} />
          <Route path="/accessories" element={<ItemGrid category="accessories" searchQuery={searchQuery} />} />
          <Route path="/media" element={<ItemGrid category="media" searchQuery={searchQuery} />} />
          <Route path="/collectibles" element={<ItemGrid category="collectibles" searchQuery={searchQuery} />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
