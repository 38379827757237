import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="footer">
      <p>&copy; 2024, Time Machine Thrift, Powered by &copy; Twen Studios</p>
      <p>
        <Link to="/refund-policy">Refund Policy</Link>
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/shipping">Shipping Policy</Link>
        <Link to="/terms">Terms of Service</Link>
        <Link to="/contact">Contact</Link>

        <a href="https://www.instagram.com/timemachinethriftstore" target="_blank" rel="noopener noreferrer">
          <img src="./insta.png" alt="Instagram" className="instagram-logo" />
        </a>

      </p>

    </div>
  );
};

export default Footer;
